<!--
 * @Author: your name
 * @Date: 2021-04-16 16:24:47
 * @LastEditTime: 2021-05-28 15:02:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /lpt_edu_pc/src/views/About.vue
-->
<template>
	<div class="about">
		<Header :baseInfo="baseInfo"/>
		<div class="swiper_banner" v-if="banner.length > 0" @mouseenter="on_swiper_enter" @mouseleave="on_swiper_leave">
			<swiper ref="mySwiperBanner" :options="swiperBanner" v-if="banner.length > 1">
				<swiper-slide v-for="(item , index) in banner" :key="index">
					<img style="width:100%;height:100%;" :src="item.pic" alt="">
				</swiper-slide>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
			<img @click="adDetail(banner[0].url)" style="width:100%;" v-else :src="banner[0].pic" alt="">
		</div>
		<div class="company_info before">
			<h4 class="home_title">公司简介</h4>
			<p class="sub_title">书法练字加盟认准六品堂</p>
			<div class="width_1200 margin_auto d_flex justify_content_between">
				<div class="company_info_desc d_flex flex_column justify_content_between">
					<div>
						<p>六品书院定位于一站式创新书法教育综合服务平台，产品整合“教研、教学、服务、营销、管理”五大板块，以书法学习为基础，以互联网为核心，为线下教培机构提供现代化的书法教育解决方案。</p>
						<p>六品书院通过双师书法课堂、多媒体动画课程、SAAS书法教育平台和AI点评系统，有效提高了机构的教学效率、实现校区数据化运营，让传统书法学习变得简单、高效。自2020年开放加盟以来，目前加盟校已经覆盖全国22个省，4个自治区，4个直辖市，以燎原之势席卷大江南北。</p>
						<p>在2021年，六品书院将依托于自身强大的课程和技术研发能力，为更多的线下机构提供更专业、优质、高效的服务，书写传统经典，继承东方文化，让中国人写好中国字！</p>
						<p>堂堂正正做人，认认真真练字，练字就选六品堂。</p>
					</div>
					<div>
						<div class="c_title">
							<b></b>
							<h5>企业愿景</h5>
						</div>
						<p style="margin-top:12px;margin-bottom:0;">以产品技术和数据驱动，秉承敬畏之心深耕书法教育，做书法生态的领航者。</p>
					</div>
				</div>
				<div class="company_info_pic">
					<div class="info_pic_box">
						<el-image :src="baseImgUrl + 'about_info_1.png'"></el-image>
					</div>
					<div class="info_pic_box">
						<el-image :src="baseImgUrl + 'about_info_2.png'"></el-image>
					</div>
					<div class="info_pic_box">
						<el-image :src="baseImgUrl + 'about_info_3.png'"></el-image>
					</div>
				</div>
			</div>
			<div class="sense_value width_1200 margin_auto before">
				<div class="c_title" style="margin-top:50px;">
					<b></b>
					<h5>企业价值观</h5>
				</div>
				<div class="sense_content">
					<div class="d_flex">
						<div class="d_flex" @mouseover="sense1Over(index)" @mouseout="sense1Out()" v-for="(item , index) in senseList[0]" :key="index">
							<img class="sense_pic" :src="baseImgUrl + item.url" alt="">
							<div :class="['sense_item' , 'd_flex_row_center' , active1 === index ? 'active' : '']" :style="'background:url('+baseImgUrl+'sense_back_1.png)'">
								<div class="d_flex_row_center"> 
									<p>{{item.content}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="d_flex" style="margin-top:20px;">
						<div class="d_flex" @mouseover="sense2Over(index)" @mouseout="sense2Out()" v-for="(item , index) in senseList[1]" :key="index">
							<img class="sense_pic" :src="baseImgUrl + item.url" alt="">
							<div :class="['sense_item' , 'd_flex_row_center' , active2 === index ? 'active' : '']" :style="'background:url('+baseImgUrl+'sense_back_2.png)'">
								<div class="d_flex_row_center"> 
									<p>{{item.content}}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h4 class="home_title">企业荣誉</h4>
			<p class="sub_title">让中国人写好中国字</p>
			<div class="about_honors" :style="'background:url('+baseImgUrl+'honor_bg.png);background-size:contain'">
				<div class="width_1200 margin_auto honors_items d_flex flex_wrap justify_content_around align_content_start">
					<img v-for="item in honors" :key="item.id" :src="baseImgUrl + item.url" alt="">
				</div>
			</div>
		</div>
		<div class="league_container">
			<div :class="[position === 'fixed' ? 'fixed' : 'relative' , 'league_position']">
				<League @openPop="openPop()" :baseInfo="baseInfo"/>
			</div>
		</div>
		<div ref="footer_container">
			<Footer :baseInfo="baseInfo"/>
		</div>
		<Form v-show="isShow" @closePop="closePop()" />
		<Top :baseInfo="baseInfo" v-show="isTopShow" @openPop="openPop()"/>
	</div>
</template>
	
<script>
import {config} from '../config';	
import mixin from '../utils/mixin';
export default {
	mixins:[mixin],
	components:{
		"Form":()=>import('../components/Form')
	},
	data () {
		return {
			// banner:['about_banner.png' , 'home_banner2.png' , 'home_banner3.png'],
			baseImgUrl:config.baseImgUrl,
			senseList:[
				[
					{url:'sense_ren.png' , content:'仁者爱人，对员工个人的品质品德提出了要求. 仁爱，是一种发自内心的对客户、对他人的善良、宽仁和慈爱。'},
					{url:'sense_yi.png' , content:'在与客户的合作过程中，当合作顺利，大家共同庆祝，分享喜悦；当遇到困难时，则应与客户共同应对，共勉前行。'},
					{url:'sense_li.png' , content:'以礼待人是修养有风度的表现，也是人格魅力的外在表露。无规矩，不成方圆，一家优秀的企业，员工一定是尊重客户，敬畏工作和遵守制度的。'},
				],
				[
					{url:'sense_zhi.png' , content:'丰富见识，广泛学习，时刻了解行业动态，提升个人知识水平，为客户带来价值。我们处于变化的时代与行业中，拥抱创新是一种心态，是进步的前提。'},
					{url:'sense_xin.png' , content:'世界上最宝贵的是信任，最脆弱的也是信任。你简单，世界也简单，工作中，与客户保持互相信任，问题就简单了，合作也因此高效。'},
					{url:'sense_xiao.png' , content:'百善孝为先，一个人只有心怀小家，才能博爱大家，若连最基础的孝也做不到，则难以立足于世。'},
				]
			],
			active1:0,
			active2:2,
			honors:[
				{id:1 , url:'home_honor_1.png'},
				{id:2 , url:'home_honor_2.png'},
				{id:3 , url:'home_honor_3.png'},
				{id:4 , url:'home_honor_4.png'},
				{id:5 , url:'home_honor_5.png'},
				{id:6 , url:'home_honor_6.png'},
			],
		};
	},

	created(){},

	mounted(){
		
	},
	
	
   	methods: {
	   sense1Over(index){
		   this.active1 = index
	   },
	   sense1Out(){
		   this.active1 = 0
	   },
	   sense2Over(index){
		   this.active2 = index
	   },
	   sense2Out(){
		   this.active2 = 2;
	   },
   	}
}

</script>
<style lang='scss' scoped>
	.about{
		.banner{
			width:100%;
			// height:540px;
		}
		.company_info{
			.home_title{
				margin-top:88px;
			}
			.sub_title{
				margin-bottom:50px;
			}
			.c_title{
				color: #333333;
				font-size:24px;
				font-weight: bold;
				text-align: left;
				b{
					display: inline-block;
					width: 6px;
					height:12px;
					background: #B91829;
				}
				h5{
					display: inline-block;
					margin-left:10px;
				}
			}
			.company_info_desc{
				font-size:18px;
				color:#333;
				text-align: left;
				padding-right:50px;
				div{
					p{
						line-height: 30px;
						margin-bottom:20px;
					}
				}
			}
			.company_info_pic{
				width:470px;
				.info_pic_box{
					width:470px;
					height:180px;
					margin-bottom:20px;
					overflow: hidden;
					.el-image{
						transition: all 1s ease 0s;
						transform: scale(1);
					}
				}
				.info_pic_box:last-child{
					margin-bottom:0;
				}
				.info_pic_box:hover{
					.el-image{
						transform: scale(1.1);
					}
				}
			}
			.sense_value{
				.sense_content{
					margin-top:50px;
					.sense_pic{
						width: 220px;
						height:170px;
					}
					.sense_item{
						height:170px;
						width: 0;
						opacity: 0;
						text-align: center;
						overflow: hidden;
						transition: all linear .4s;
						// display: none;
						position: relative;
						div{
							position: absolute;
							width:100%;
							height:100%;
							top:0;
							left:0;
							p{
								padding:0 50px;
								line-height: 30px;
							}
						}
						
					}
					.sense_item.active{
						width:540px;
						opacity: 1;
						// display: block;
					}
				}
			}
			.about_honors{
				height:560px;
				.honors_items{
					background: #6A838C;
					padding:0 60px;
					height: 100%;
					img{
						max-width:292px;
						max-height:230px;
						margin-top:30px;
					}
				}
			}
		}
	}
</style>
